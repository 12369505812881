/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the GroupUpdate interface.
 */
export function instanceOfGroupUpdate(value) {
    if (!('name' in value) || value['name'] === undefined)
        return false;
    if (!('mailingList' in value) || value['mailingList'] === undefined)
        return false;
    if (!('roleIds' in value) || value['roleIds'] === undefined)
        return false;
    return true;
}
export function GroupUpdateFromJSON(json) {
    return GroupUpdateFromJSONTyped(json, false);
}
export function GroupUpdateFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'name': json['name'],
        'color': json['color'] == null ? undefined : json['color'],
        'mailingList': json['mailing_list'],
        'roleIds': json['role_ids'],
    };
}
export function GroupUpdateToJSON(json) {
    return GroupUpdateToJSONTyped(json, false);
}
export function GroupUpdateToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'name': value['name'],
        'color': value['color'],
        'mailing_list': value['mailingList'],
        'role_ids': value['roleIds'],
    };
}
