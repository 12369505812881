/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the ResetPassword interface.
 */
export function instanceOfResetPassword(value) {
    if (!('email' in value) || value['email'] === undefined)
        return false;
    if (!('password' in value) || value['password'] === undefined)
        return false;
    if (!('passwordConfirm' in value) || value['passwordConfirm'] === undefined)
        return false;
    return true;
}
export function ResetPasswordFromJSON(json) {
    return ResetPasswordFromJSONTyped(json, false);
}
export function ResetPasswordFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'email': json['email'],
        'password': json['password'],
        'passwordConfirm': json['password_confirm'],
    };
}
export function ResetPasswordToJSON(json) {
    return ResetPasswordToJSONTyped(json, false);
}
export function ResetPasswordToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'email': value['email'],
        'password': value['password'],
        'password_confirm': value['passwordConfirm'],
    };
}
