/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the MyStats interface.
 */
export function instanceOfMyStats(value) {
    if (!('nResponses' in value) || value['nResponses'] === undefined)
        return false;
    if (!('nPositiveResponses' in value) || value['nPositiveResponses'] === undefined)
        return false;
    if (!('avgResponseTime' in value) || value['avgResponseTime'] === undefined)
        return false;
    if (!('nUpcommingResponses' in value) || value['nUpcommingResponses'] === undefined)
        return false;
    return true;
}
export function MyStatsFromJSON(json) {
    return MyStatsFromJSONTyped(json, false);
}
export function MyStatsFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'nResponses': json['n_responses'],
        'nPositiveResponses': json['n_positive_responses'],
        'avgResponseTime': json['avg_response_time'],
        'nUpcommingResponses': json['n_upcomming_responses'],
    };
}
export function MyStatsToJSON(json) {
    return MyStatsToJSONTyped(json, false);
}
export function MyStatsToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'n_responses': value['nResponses'],
        'n_positive_responses': value['nPositiveResponses'],
        'avg_response_time': value['avgResponseTime'],
        'n_upcomming_responses': value['nUpcommingResponses'],
    };
}
