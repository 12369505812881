/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the ProfileCreate interface.
 */
export function instanceOfProfileCreate(value) {
    if (!('firstName' in value) || value['firstName'] === undefined)
        return false;
    if (!('lastName' in value) || value['lastName'] === undefined)
        return false;
    if (!('instrumentId' in value) || value['instrumentId'] === undefined)
        return false;
    if (!('groupIds' in value) || value['groupIds'] === undefined)
        return false;
    if (!('email' in value) || value['email'] === undefined)
        return false;
    return true;
}
export function ProfileCreateFromJSON(json) {
    return ProfileCreateFromJSONTyped(json, false);
}
export function ProfileCreateFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'firstName': json['first_name'],
        'lastName': json['last_name'],
        'pictureKey': json['picture_key'] == null ? undefined : json['picture_key'],
        'instrumentId': json['instrument_id'],
        'groupIds': json['group_ids'],
        'email': json['email'],
    };
}
export function ProfileCreateToJSON(json) {
    return ProfileCreateToJSONTyped(json, false);
}
export function ProfileCreateToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'first_name': value['firstName'],
        'last_name': value['lastName'],
        'picture_key': value['pictureKey'],
        'instrument_id': value['instrumentId'],
        'group_ids': value['groupIds'],
        'email': value['email'],
    };
}
