/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FileOrFolderTypeFromJSON, FileOrFolderTypeToJSON, } from './FileOrFolderType';
/**
 * Check if a given object implements the FileOrFolder interface.
 */
export function instanceOfFileOrFolder(value) {
    if (!('name' in value) || value['name'] === undefined)
        return false;
    if (!('type' in value) || value['type'] === undefined)
        return false;
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('fileUrl' in value) || value['fileUrl'] === undefined)
        return false;
    return true;
}
export function FileOrFolderFromJSON(json) {
    return FileOrFolderFromJSONTyped(json, false);
}
export function FileOrFolderFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'name': json['name'],
        'type': FileOrFolderTypeFromJSON(json['type']),
        'id': json['id'],
        'parentId': json['parent_id'] == null ? undefined : json['parent_id'],
        'fileKey': json['file_key'] == null ? undefined : json['file_key'],
        'fileUrl': json['fileUrl'],
    };
}
export function FileOrFolderToJSON(json) {
    return FileOrFolderToJSONTyped(json, false);
}
export function FileOrFolderToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'name': value['name'],
        'type': FileOrFolderTypeToJSON(value['type']),
        'id': value['id'],
        'parent_id': value['parentId'],
        'file_key': value['fileKey'],
        'fileUrl': value['fileUrl'],
    };
}
