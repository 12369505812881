/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the Response interface.
 */
export function instanceOfResponse(value) {
    if (!('value' in value) || value['value'] === undefined)
        return false;
    if (!('userId' in value) || value['userId'] === undefined)
        return false;
    if (!('eventId' in value) || value['eventId'] === undefined)
        return false;
    if (!('date' in value) || value['date'] === undefined)
        return false;
    return true;
}
export function ResponseFromJSON(json) {
    return ResponseFromJSONTyped(json, false);
}
export function ResponseFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'value': json['value'],
        'userId': json['user_id'],
        'eventId': json['event_id'],
        'date': (new Date(json['date'])),
    };
}
export function ResponseToJSON(json) {
    return ResponseToJSONTyped(json, false);
}
export function ResponseToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'value': value['value'],
        'user_id': value['userId'],
        'event_id': value['eventId'],
        'date': ((value['date']).toISOString()),
    };
}
