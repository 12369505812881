/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const LoginType = {
    Password: 'password',
    Passkey: 'passkey'
};
export function instanceOfLoginType(value) {
    for (const key in LoginType) {
        if (Object.prototype.hasOwnProperty.call(LoginType, key)) {
            if (LoginType[key] === value) {
                return true;
            }
        }
    }
    return false;
}
export function LoginTypeFromJSON(json) {
    return LoginTypeFromJSONTyped(json, false);
}
export function LoginTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function LoginTypeToJSON(value) {
    return value;
}
export function LoginTypeToJSONTyped(value, ignoreDiscriminator) {
    return value;
}
