/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the FileOrFolderUpdate interface.
 */
export function instanceOfFileOrFolderUpdate(value) {
    if (!('name' in value) || value['name'] === undefined)
        return false;
    if (!('parentId' in value) || value['parentId'] === undefined)
        return false;
    return true;
}
export function FileOrFolderUpdateFromJSON(json) {
    return FileOrFolderUpdateFromJSONTyped(json, false);
}
export function FileOrFolderUpdateFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'name': json['name'],
        'parentId': json['parent_id'],
    };
}
export function FileOrFolderUpdateToJSON(json) {
    return FileOrFolderUpdateToJSONTyped(json, false);
}
export function FileOrFolderUpdateToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'name': value['name'],
        'parent_id': value['parentId'],
    };
}
