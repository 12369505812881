/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the MinimalGroup interface.
 */
export function instanceOfMinimalGroup(value) {
    if (!('name' in value) || value['name'] === undefined)
        return false;
    if (!('mailingList' in value) || value['mailingList'] === undefined)
        return false;
    if (!('id' in value) || value['id'] === undefined)
        return false;
    return true;
}
export function MinimalGroupFromJSON(json) {
    return MinimalGroupFromJSONTyped(json, false);
}
export function MinimalGroupFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'name': json['name'],
        'color': json['color'] == null ? undefined : json['color'],
        'mailingList': json['mailing_list'],
        'id': json['id'],
    };
}
export function MinimalGroupToJSON(json) {
    return MinimalGroupToJSONTyped(json, false);
}
export function MinimalGroupToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'name': value['name'],
        'color': value['color'],
        'mailing_list': value['mailingList'],
        'id': value['id'],
    };
}
