/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LoginTypeFromJSON, LoginTypeToJSON, } from './LoginType';
/**
 * Check if a given object implements the LoginData interface.
 */
export function instanceOfLoginData(value) {
    if (!('type' in value) || value['type'] === undefined)
        return false;
    if (!('email' in value) || value['email'] === undefined)
        return false;
    return true;
}
export function LoginDataFromJSON(json) {
    return LoginDataFromJSONTyped(json, false);
}
export function LoginDataFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'type': LoginTypeFromJSON(json['type']),
        'email': json['email'],
        'password': json['password'] == null ? undefined : json['password'],
        'passkey': json['passkey'] == null ? undefined : json['passkey'],
    };
}
export function LoginDataToJSON(json) {
    return LoginDataToJSONTyped(json, false);
}
export function LoginDataToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'type': LoginTypeToJSON(value['type']),
        'email': value['email'],
        'password': value['password'],
        'passkey': value['passkey'],
    };
}
