/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime';
import { EventFromJSON, EventCreateToJSON, ResFromJSON, ResponseFromJSON, ResponseCreateToJSON, } from '../models/index';
/**
 *
 */
export class EventsApi extends runtime.BaseAPI {
    /**
     * Create Event
     */
    createEventApiV1EventsPostRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['eventCreate'] == null) {
                throw new runtime.RequiredError('eventCreate', 'Required parameter "eventCreate" was null or undefined when calling createEventApiV1EventsPost().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (requestParameters['authorization'] != null) {
                headerParameters['authorization'] = String(requestParameters['authorization']);
            }
            const response = yield this.request({
                path: `/api/v1/events/`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: EventCreateToJSON(requestParameters['eventCreate']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => EventFromJSON(jsonValue));
        });
    }
    /**
     * Create Event
     */
    createEventApiV1EventsPost(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.createEventApiV1EventsPostRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Create Response
     */
    createResponseApiV1EventsEventIdResponsesPutRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['eventId'] == null) {
                throw new runtime.RequiredError('eventId', 'Required parameter "eventId" was null or undefined when calling createResponseApiV1EventsEventIdResponsesPut().');
            }
            if (requestParameters['responseCreate'] == null) {
                throw new runtime.RequiredError('responseCreate', 'Required parameter "responseCreate" was null or undefined when calling createResponseApiV1EventsEventIdResponsesPut().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (requestParameters['authorization'] != null) {
                headerParameters['authorization'] = String(requestParameters['authorization']);
            }
            const response = yield this.request({
                path: `/api/v1/events/{event_id}/responses`.replace(`{${"event_id"}}`, encodeURIComponent(String(requestParameters['eventId']))),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: ResponseCreateToJSON(requestParameters['responseCreate']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ResponseFromJSON(jsonValue));
        });
    }
    /**
     * Create Response
     */
    createResponseApiV1EventsEventIdResponsesPut(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.createResponseApiV1EventsEventIdResponsesPutRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Create Response By Token
     */
    createResponseByTokenApiV1ResponsesLinkSavePutRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['token'] == null) {
                throw new runtime.RequiredError('token', 'Required parameter "token" was null or undefined when calling createResponseByTokenApiV1ResponsesLinkSavePut().');
            }
            if (requestParameters['responseCreate'] == null) {
                throw new runtime.RequiredError('responseCreate', 'Required parameter "responseCreate" was null or undefined when calling createResponseByTokenApiV1ResponsesLinkSavePut().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (requestParameters['token'] != null) {
                headerParameters['token'] = String(requestParameters['token']);
            }
            const response = yield this.request({
                path: `/api/v1/responses/link/save`,
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: ResponseCreateToJSON(requestParameters['responseCreate']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ResponseFromJSON(jsonValue));
        });
    }
    /**
     * Create Response By Token
     */
    createResponseByTokenApiV1ResponsesLinkSavePut(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.createResponseByTokenApiV1ResponsesLinkSavePutRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Delete Event
     */
    deleteEventApiV1EventsEventIdDeleteRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['eventId'] == null) {
                throw new runtime.RequiredError('eventId', 'Required parameter "eventId" was null or undefined when calling deleteEventApiV1EventsEventIdDelete().');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (requestParameters['authorization'] != null) {
                headerParameters['authorization'] = String(requestParameters['authorization']);
            }
            const response = yield this.request({
                path: `/api/v1/events/{event_id}`.replace(`{${"event_id"}}`, encodeURIComponent(String(requestParameters['eventId']))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Delete Event
     */
    deleteEventApiV1EventsEventIdDelete(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.deleteEventApiV1EventsEventIdDeleteRaw(requestParameters, initOverrides);
        });
    }
    /**
     * Export Ics
     */
    exportIcsApiV1EventsExportIcsGetRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/api/v1/events/export/ics`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            if (this.isJsonMime(response.headers.get('content-type'))) {
                return new runtime.JSONApiResponse(response);
            }
            else {
                return new runtime.TextApiResponse(response);
            }
        });
    }
    /**
     * Export Ics
     */
    exportIcsApiV1EventsExportIcsGet(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.exportIcsApiV1EventsExportIcsGetRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get Event
     */
    getEventApiV1EventsEventIdGetRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['eventId'] == null) {
                throw new runtime.RequiredError('eventId', 'Required parameter "eventId" was null or undefined when calling getEventApiV1EventsEventIdGet().');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (requestParameters['authorization'] != null) {
                headerParameters['authorization'] = String(requestParameters['authorization']);
            }
            const response = yield this.request({
                path: `/api/v1/events/{event_id}`.replace(`{${"event_id"}}`, encodeURIComponent(String(requestParameters['eventId']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => EventFromJSON(jsonValue));
        });
    }
    /**
     * Get Event
     */
    getEventApiV1EventsEventIdGet(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getEventApiV1EventsEventIdGetRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get Response By Token
     */
    getResponseByTokenApiV1ResponsesLinkPrepareGetRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['token'] == null) {
                throw new runtime.RequiredError('token', 'Required parameter "token" was null or undefined when calling getResponseByTokenApiV1ResponsesLinkPrepareGet().');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (requestParameters['token'] != null) {
                headerParameters['token'] = String(requestParameters['token']);
            }
            const response = yield this.request({
                path: `/api/v1/responses/link/prepare`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ResFromJSON(jsonValue));
        });
    }
    /**
     * Get Response By Token
     */
    getResponseByTokenApiV1ResponsesLinkPrepareGet(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getResponseByTokenApiV1ResponsesLinkPrepareGetRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * List Events
     */
    listEventsApiV1EventsGetRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters['limit'] != null) {
                queryParameters['limit'] = requestParameters['limit'];
            }
            if (requestParameters['dateGte'] != null) {
                queryParameters['date__gte'] = requestParameters['dateGte'].toISOString();
            }
            if (requestParameters['dateLt'] != null) {
                queryParameters['date__lt'] = requestParameters['dateLt'].toISOString();
            }
            if (requestParameters['isInDoodle'] != null) {
                queryParameters['is_in_doodle'] = requestParameters['isInDoodle'];
            }
            if (requestParameters['ordering'] != null) {
                queryParameters['ordering'] = requestParameters['ordering'];
            }
            const headerParameters = {};
            if (requestParameters['authorization'] != null) {
                headerParameters['authorization'] = String(requestParameters['authorization']);
            }
            const response = yield this.request({
                path: `/api/v1/events/`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(EventFromJSON));
        });
    }
    /**
     * List Events
     */
    listEventsApiV1EventsGet() {
        return __awaiter(this, arguments, void 0, function* (requestParameters = {}, initOverrides) {
            const response = yield this.listEventsApiV1EventsGetRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * List Responses
     */
    listResponsesApiV1ResponsesGetRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters['userId'] != null) {
                queryParameters['user_id'] = requestParameters['userId'];
            }
            const headerParameters = {};
            if (requestParameters['authorization'] != null) {
                headerParameters['authorization'] = String(requestParameters['authorization']);
            }
            const response = yield this.request({
                path: `/api/v1/responses/`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ResponseFromJSON));
        });
    }
    /**
     * List Responses
     */
    listResponsesApiV1ResponsesGet() {
        return __awaiter(this, arguments, void 0, function* (requestParameters = {}, initOverrides) {
            const response = yield this.listResponsesApiV1ResponsesGetRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Update Event
     */
    updateEventApiV1EventsEventIdPutRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['eventId'] == null) {
                throw new runtime.RequiredError('eventId', 'Required parameter "eventId" was null or undefined when calling updateEventApiV1EventsEventIdPut().');
            }
            if (requestParameters['eventCreate'] == null) {
                throw new runtime.RequiredError('eventCreate', 'Required parameter "eventCreate" was null or undefined when calling updateEventApiV1EventsEventIdPut().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (requestParameters['authorization'] != null) {
                headerParameters['authorization'] = String(requestParameters['authorization']);
            }
            const response = yield this.request({
                path: `/api/v1/events/{event_id}`.replace(`{${"event_id"}}`, encodeURIComponent(String(requestParameters['eventId']))),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: EventCreateToJSON(requestParameters['eventCreate']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => EventFromJSON(jsonValue));
        });
    }
    /**
     * Update Event
     */
    updateEventApiV1EventsEventIdPut(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.updateEventApiV1EventsEventIdPutRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
