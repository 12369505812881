/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the Role interface.
 */
export function instanceOfRole(value) {
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('description' in value) || value['description'] === undefined)
        return false;
    return true;
}
export function RoleFromJSON(json) {
    return RoleFromJSONTyped(json, false);
}
export function RoleFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'],
        'description': json['description'],
    };
}
export function RoleToJSON(json) {
    return RoleToJSONTyped(json, false);
}
export function RoleToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'description': value['description'],
    };
}
