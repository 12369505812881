/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MinimalGroupFromJSON, MinimalGroupToJSON, } from './MinimalGroup';
/**
 * Check if a given object implements the Profile interface.
 */
export function instanceOfProfile(value) {
    if (!('firstName' in value) || value['firstName'] === undefined)
        return false;
    if (!('lastName' in value) || value['lastName'] === undefined)
        return false;
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('groups' in value) || value['groups'] === undefined)
        return false;
    if (!('pictureUrl' in value) || value['pictureUrl'] === undefined)
        return false;
    return true;
}
export function ProfileFromJSON(json) {
    return ProfileFromJSONTyped(json, false);
}
export function ProfileFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'firstName': json['first_name'],
        'lastName': json['last_name'],
        'pictureKey': json['picture_key'] == null ? undefined : json['picture_key'],
        'id': json['id'],
        'groups': (json['groups'].map(MinimalGroupFromJSON)),
        'instrument': json['instrument'] == null ? undefined : MinimalGroupFromJSON(json['instrument']),
        'pictureUrl': json['picture_url'],
    };
}
export function ProfileToJSON(json) {
    return ProfileToJSONTyped(json, false);
}
export function ProfileToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'first_name': value['firstName'],
        'last_name': value['lastName'],
        'picture_key': value['pictureKey'],
        'id': value['id'],
        'groups': (value['groups'].map(MinimalGroupToJSON)),
        'instrument': MinimalGroupToJSON(value['instrument']),
        'picture_url': value['pictureUrl'],
    };
}
