/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the ResponseCreate interface.
 */
export function instanceOfResponseCreate(value) {
    if (!('value' in value) || value['value'] === undefined)
        return false;
    return true;
}
export function ResponseCreateFromJSON(json) {
    return ResponseCreateFromJSONTyped(json, false);
}
export function ResponseCreateFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'value': json['value'],
    };
}
export function ResponseCreateToJSON(json) {
    return ResponseCreateToJSONTyped(json, false);
}
export function ResponseCreateToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'value': value['value'],
    };
}
