/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ResponseFromJSON, ResponseToJSON, } from './Response';
import { EventFromJSON, EventToJSON, } from './Event';
import { MyProfileUpdateFromJSON, MyProfileUpdateToJSON, } from './MyProfileUpdate';
/**
 * Check if a given object implements the Res interface.
 */
export function instanceOfRes(value) {
    if (!('event' in value) || value['event'] === undefined)
        return false;
    if (!('user' in value) || value['user'] === undefined)
        return false;
    return true;
}
export function ResFromJSON(json) {
    return ResFromJSONTyped(json, false);
}
export function ResFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'event': EventFromJSON(json['event']),
        'user': MyProfileUpdateFromJSON(json['user']),
        'response': json['response'] == null ? undefined : ResponseFromJSON(json['response']),
    };
}
export function ResToJSON(json) {
    return ResToJSONTyped(json, false);
}
export function ResToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'event': EventToJSON(value['event']),
        'user': MyProfileUpdateToJSON(value['user']),
        'response': ResponseToJSON(value['response']),
    };
}
