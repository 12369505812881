import { QueryClientProvider } from '@tanstack/react-query';
import { Configuration, DefaultConfig } from 'bagad-client';
import { Toaster } from 'react-hot-toast';
import { RouterProvider } from 'react-router-dom';
import './App.css';
import { queryClient } from './config/client';
import router from './config/router';
import env from './env';

DefaultConfig.config = new Configuration({ basePath: env.VITE_BBE2_API_URL });

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Toaster position="bottom-center" />
      <RouterProvider router={router} />
    </QueryClientProvider>
  );
}

export default App;
