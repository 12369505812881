/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CostumeFromJSON, CostumeToJSON, } from './Costume';
/**
 * Check if a given object implements the EventCreate interface.
 */
export function instanceOfEventCreate(value) {
    if (!('title' in value) || value['title'] === undefined)
        return false;
    if (!('description' in value) || value['description'] === undefined)
        return false;
    if (!('date' in value) || value['date'] === undefined)
        return false;
    if (!('costume' in value) || value['costume'] === undefined)
        return false;
    if (!('category' in value) || value['category'] === undefined)
        return false;
    if (!('isInDoodle' in value) || value['isInDoodle'] === undefined)
        return false;
    return true;
}
export function EventCreateFromJSON(json) {
    return EventCreateFromJSONTyped(json, false);
}
export function EventCreateFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'title': json['title'],
        'description': json['description'],
        'date': (new Date(json['date'])),
        'costume': CostumeFromJSON(json['costume']),
        'category': json['category'],
        'isInDoodle': json['is_in_doodle'],
    };
}
export function EventCreateToJSON(json) {
    return EventCreateToJSONTyped(json, false);
}
export function EventCreateToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'title': value['title'],
        'description': value['description'],
        'date': ((value['date']).toISOString().substring(0, 10)),
        'costume': CostumeToJSON(value['costume']),
        'category': value['category'],
        'is_in_doodle': value['isInDoodle'],
    };
}
