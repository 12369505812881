/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime';
import { FileOrFolderFromJSON, FileOrFolderUpdateToJSON, FolderCreateToJSON, } from '../models/index';
/**
 *
 */
export class FilesApi extends runtime.BaseAPI {
    /**
     * Create a new folder.
     * Create Folder
     */
    createFolderApiV1FilesFolderIdPostRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['folderId'] == null) {
                throw new runtime.RequiredError('folderId', 'Required parameter "folderId" was null or undefined when calling createFolderApiV1FilesFolderIdPost().');
            }
            if (requestParameters['folderCreate'] == null) {
                throw new runtime.RequiredError('folderCreate', 'Required parameter "folderCreate" was null or undefined when calling createFolderApiV1FilesFolderIdPost().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (requestParameters['authorization'] != null) {
                headerParameters['authorization'] = String(requestParameters['authorization']);
            }
            const response = yield this.request({
                path: `/api/v1/files/{folder_id}`.replace(`{${"folder_id"}}`, encodeURIComponent(String(requestParameters['folderId']))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: FolderCreateToJSON(requestParameters['folderCreate']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => FileOrFolderFromJSON(jsonValue));
        });
    }
    /**
     * Create a new folder.
     * Create Folder
     */
    createFolderApiV1FilesFolderIdPost(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.createFolderApiV1FilesFolderIdPostRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Delete an existing file or folder.
     * Delete File
     */
    deleteFileApiV1FilesFileIdDeleteRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['fileId'] == null) {
                throw new runtime.RequiredError('fileId', 'Required parameter "fileId" was null or undefined when calling deleteFileApiV1FilesFileIdDelete().');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (requestParameters['authorization'] != null) {
                headerParameters['authorization'] = String(requestParameters['authorization']);
            }
            const response = yield this.request({
                path: `/api/v1/files/{file_id}`.replace(`{${"file_id"}}`, encodeURIComponent(String(requestParameters['fileId']))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Delete an existing file or folder.
     * Delete File
     */
    deleteFileApiV1FilesFileIdDelete(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.deleteFileApiV1FilesFileIdDeleteRaw(requestParameters, initOverrides);
        });
    }
    /**
     * Get breadcrumb for a file.
     * Get Breadcrumb
     */
    getBreadcrumbApiV1FilesFileIdBreadcrumbGetRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['fileId'] == null) {
                throw new runtime.RequiredError('fileId', 'Required parameter "fileId" was null or undefined when calling getBreadcrumbApiV1FilesFileIdBreadcrumbGet().');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (requestParameters['authorization'] != null) {
                headerParameters['authorization'] = String(requestParameters['authorization']);
            }
            const response = yield this.request({
                path: `/api/v1/files/{file_id}/breadcrumb`.replace(`{${"file_id"}}`, encodeURIComponent(String(requestParameters['fileId']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FileOrFolderFromJSON));
        });
    }
    /**
     * Get breadcrumb for a file.
     * Get Breadcrumb
     */
    getBreadcrumbApiV1FilesFileIdBreadcrumbGet(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getBreadcrumbApiV1FilesFileIdBreadcrumbGetRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get a file or folder by id.
     * Get File
     */
    getFileApiV1FilesFileIdGetRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['fileId'] == null) {
                throw new runtime.RequiredError('fileId', 'Required parameter "fileId" was null or undefined when calling getFileApiV1FilesFileIdGet().');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (requestParameters['authorization'] != null) {
                headerParameters['authorization'] = String(requestParameters['authorization']);
            }
            const response = yield this.request({
                path: `/api/v1/files/{file_id}`.replace(`{${"file_id"}}`, encodeURIComponent(String(requestParameters['fileId']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => FileOrFolderFromJSON(jsonValue));
        });
    }
    /**
     * Get a file or folder by id.
     * Get File
     */
    getFileApiV1FilesFileIdGet(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getFileApiV1FilesFileIdGetRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get root folder entity.
     * Get Root
     */
    getRootApiV1FilesRootGetRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (requestParameters['authorization'] != null) {
                headerParameters['authorization'] = String(requestParameters['authorization']);
            }
            const response = yield this.request({
                path: `/api/v1/files/root`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => FileOrFolderFromJSON(jsonValue));
        });
    }
    /**
     * Get root folder entity.
     * Get Root
     */
    getRootApiV1FilesRootGet() {
        return __awaiter(this, arguments, void 0, function* (requestParameters = {}, initOverrides) {
            const response = yield this.getRootApiV1FilesRootGetRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get all chidren of a folder.
     * List Children
     */
    listChildrenApiV1FilesFolderIdChildrenGetRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['folderId'] == null) {
                throw new runtime.RequiredError('folderId', 'Required parameter "folderId" was null or undefined when calling listChildrenApiV1FilesFolderIdChildrenGet().');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (requestParameters['authorization'] != null) {
                headerParameters['authorization'] = String(requestParameters['authorization']);
            }
            const response = yield this.request({
                path: `/api/v1/files/{folder_id}/children`.replace(`{${"folder_id"}}`, encodeURIComponent(String(requestParameters['folderId']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FileOrFolderFromJSON));
        });
    }
    /**
     * Get all chidren of a folder.
     * List Children
     */
    listChildrenApiV1FilesFolderIdChildrenGet(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.listChildrenApiV1FilesFolderIdChildrenGetRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * List recent files.
     * List Files
     */
    listFilesApiV1FilesGetRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters['t'] != null) {
                queryParameters['t'] = requestParameters['t'];
            }
            if (requestParameters['limit'] != null) {
                queryParameters['limit'] = requestParameters['limit'];
            }
            const headerParameters = {};
            if (requestParameters['authorization'] != null) {
                headerParameters['authorization'] = String(requestParameters['authorization']);
            }
            const response = yield this.request({
                path: `/api/v1/files/`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FileOrFolderFromJSON));
        });
    }
    /**
     * List recent files.
     * List Files
     */
    listFilesApiV1FilesGet() {
        return __awaiter(this, arguments, void 0, function* (requestParameters = {}, initOverrides) {
            const response = yield this.listFilesApiV1FilesGetRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Update an existing file or folder
     * Update File
     */
    updateFileApiV1FilesFileIdPutRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['fileId'] == null) {
                throw new runtime.RequiredError('fileId', 'Required parameter "fileId" was null or undefined when calling updateFileApiV1FilesFileIdPut().');
            }
            if (requestParameters['fileOrFolderUpdate'] == null) {
                throw new runtime.RequiredError('fileOrFolderUpdate', 'Required parameter "fileOrFolderUpdate" was null or undefined when calling updateFileApiV1FilesFileIdPut().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (requestParameters['authorization'] != null) {
                headerParameters['authorization'] = String(requestParameters['authorization']);
            }
            const response = yield this.request({
                path: `/api/v1/files/{file_id}`.replace(`{${"file_id"}}`, encodeURIComponent(String(requestParameters['fileId']))),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: FileOrFolderUpdateToJSON(requestParameters['fileOrFolderUpdate']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => FileOrFolderFromJSON(jsonValue));
        });
    }
    /**
     * Update an existing file or folder
     * Update File
     */
    updateFileApiV1FilesFileIdPut(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.updateFileApiV1FilesFileIdPutRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Upload a file.
     * Upload File
     */
    uploadFileApiV1FilesFolderIdUploadPostRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['folderId'] == null) {
                throw new runtime.RequiredError('folderId', 'Required parameter "folderId" was null or undefined when calling uploadFileApiV1FilesFolderIdUploadPost().');
            }
            if (requestParameters['file'] == null) {
                throw new runtime.RequiredError('file', 'Required parameter "file" was null or undefined when calling uploadFileApiV1FilesFolderIdUploadPost().');
            }
            const queryParameters = {};
            if (requestParameters['force'] != null) {
                queryParameters['force'] = requestParameters['force'];
            }
            const headerParameters = {};
            if (requestParameters['authorization'] != null) {
                headerParameters['authorization'] = String(requestParameters['authorization']);
            }
            const consumes = [
                { contentType: 'multipart/form-data' },
            ];
            // @ts-ignore: canConsumeForm may be unused
            const canConsumeForm = runtime.canConsumeForm(consumes);
            let formParams;
            let useForm = false;
            // use FormData to transmit files using content-type "multipart/form-data"
            useForm = canConsumeForm;
            if (useForm) {
                formParams = new FormData();
            }
            else {
                formParams = new URLSearchParams();
            }
            if (requestParameters['file'] != null) {
                formParams.append('file', requestParameters['file']);
            }
            const response = yield this.request({
                path: `/api/v1/files/{folder_id}/upload`.replace(`{${"folder_id"}}`, encodeURIComponent(String(requestParameters['folderId']))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: formParams,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => FileOrFolderFromJSON(jsonValue));
        });
    }
    /**
     * Upload a file.
     * Upload File
     */
    uploadFileApiV1FilesFolderIdUploadPost(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.uploadFileApiV1FilesFolderIdUploadPostRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
