/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime';
import { GetUploadUrlResponseFromJSON, GlobalStatsFromJSON, GroupFromJSON, GroupCreateToJSON, GroupUpdateToJSON, MyProfileUpdateToJSON, MyStatsFromJSON, ProfileFromJSON, ProfileCreateToJSON, ProfileUpdateToJSON, RoleFromJSON, } from '../models/index';
/**
 *
 */
export class ProfilesApi extends runtime.BaseAPI {
    /**
     * Create Group
     */
    createGroupApiV1GroupsPostRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['groupCreate'] == null) {
                throw new runtime.RequiredError('groupCreate', 'Required parameter "groupCreate" was null or undefined when calling createGroupApiV1GroupsPost().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (requestParameters['authorization'] != null) {
                headerParameters['authorization'] = String(requestParameters['authorization']);
            }
            const response = yield this.request({
                path: `/api/v1/groups/`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: GroupCreateToJSON(requestParameters['groupCreate']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => GroupFromJSON(jsonValue));
        });
    }
    /**
     * Create Group
     */
    createGroupApiV1GroupsPost(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.createGroupApiV1GroupsPostRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Create Profile
     */
    createProfileApiV1ProfilesPostRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['profileCreate'] == null) {
                throw new runtime.RequiredError('profileCreate', 'Required parameter "profileCreate" was null or undefined when calling createProfileApiV1ProfilesPost().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (requestParameters['authorization'] != null) {
                headerParameters['authorization'] = String(requestParameters['authorization']);
            }
            const response = yield this.request({
                path: `/api/v1/profiles/`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ProfileCreateToJSON(requestParameters['profileCreate']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ProfileFromJSON(jsonValue));
        });
    }
    /**
     * Create Profile
     */
    createProfileApiV1ProfilesPost(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.createProfileApiV1ProfilesPostRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get Global Stats
     */
    getGlobalStatsApiV1StatsGetRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (requestParameters['authorization'] != null) {
                headerParameters['authorization'] = String(requestParameters['authorization']);
            }
            const response = yield this.request({
                path: `/api/v1/stats/`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => GlobalStatsFromJSON(jsonValue));
        });
    }
    /**
     * Get Global Stats
     */
    getGlobalStatsApiV1StatsGet() {
        return __awaiter(this, arguments, void 0, function* (requestParameters = {}, initOverrides) {
            const response = yield this.getGlobalStatsApiV1StatsGetRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get Group
     */
    getGroupApiV1GroupsGroupIdGetRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['groupId'] == null) {
                throw new runtime.RequiredError('groupId', 'Required parameter "groupId" was null or undefined when calling getGroupApiV1GroupsGroupIdGet().');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (requestParameters['authorization'] != null) {
                headerParameters['authorization'] = String(requestParameters['authorization']);
            }
            const response = yield this.request({
                path: `/api/v1/groups/{group_id}`.replace(`{${"group_id"}}`, encodeURIComponent(String(requestParameters['groupId']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => GroupFromJSON(jsonValue));
        });
    }
    /**
     * Get Group
     */
    getGroupApiV1GroupsGroupIdGet(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getGroupApiV1GroupsGroupIdGetRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get My Profile
     */
    getMyProfileApiV1ProfilesMeGetRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (requestParameters['authorization'] != null) {
                headerParameters['authorization'] = String(requestParameters['authorization']);
            }
            const response = yield this.request({
                path: `/api/v1/profiles/me`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ProfileFromJSON(jsonValue));
        });
    }
    /**
     * Get My Profile
     */
    getMyProfileApiV1ProfilesMeGet() {
        return __awaiter(this, arguments, void 0, function* (requestParameters = {}, initOverrides) {
            const response = yield this.getMyProfileApiV1ProfilesMeGetRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get My Roles
     */
    getMyRolesApiV1ProfilesMeRolesGetRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (requestParameters['authorization'] != null) {
                headerParameters['authorization'] = String(requestParameters['authorization']);
            }
            const response = yield this.request({
                path: `/api/v1/profiles/me/roles`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     * Get My Roles
     */
    getMyRolesApiV1ProfilesMeRolesGet() {
        return __awaiter(this, arguments, void 0, function* (requestParameters = {}, initOverrides) {
            const response = yield this.getMyRolesApiV1ProfilesMeRolesGetRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get My Stats
     */
    getMyStatsApiV1StatsMeGetRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (requestParameters['authorization'] != null) {
                headerParameters['authorization'] = String(requestParameters['authorization']);
            }
            const response = yield this.request({
                path: `/api/v1/stats/me`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => MyStatsFromJSON(jsonValue));
        });
    }
    /**
     * Get My Stats
     */
    getMyStatsApiV1StatsMeGet() {
        return __awaiter(this, arguments, void 0, function* (requestParameters = {}, initOverrides) {
            const response = yield this.getMyStatsApiV1StatsMeGetRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get Profile
     */
    getProfileApiV1ProfilesProfileIdGetRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['profileId'] == null) {
                throw new runtime.RequiredError('profileId', 'Required parameter "profileId" was null or undefined when calling getProfileApiV1ProfilesProfileIdGet().');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (requestParameters['authorization'] != null) {
                headerParameters['authorization'] = String(requestParameters['authorization']);
            }
            const response = yield this.request({
                path: `/api/v1/profiles/{profile_id}`.replace(`{${"profile_id"}}`, encodeURIComponent(String(requestParameters['profileId']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ProfileFromJSON(jsonValue));
        });
    }
    /**
     * Get Profile
     */
    getProfileApiV1ProfilesProfileIdGet(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getProfileApiV1ProfilesProfileIdGetRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * List Groups
     */
    listGroupsApiV1GroupsGetRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (requestParameters['authorization'] != null) {
                headerParameters['authorization'] = String(requestParameters['authorization']);
            }
            const response = yield this.request({
                path: `/api/v1/groups/`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GroupFromJSON));
        });
    }
    /**
     * List Groups
     */
    listGroupsApiV1GroupsGet() {
        return __awaiter(this, arguments, void 0, function* (requestParameters = {}, initOverrides) {
            const response = yield this.listGroupsApiV1GroupsGetRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * List Profiles
     */
    listProfilesApiV1ProfilesGetRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (requestParameters['authorization'] != null) {
                headerParameters['authorization'] = String(requestParameters['authorization']);
            }
            const response = yield this.request({
                path: `/api/v1/profiles/`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProfileFromJSON));
        });
    }
    /**
     * List Profiles
     */
    listProfilesApiV1ProfilesGet() {
        return __awaiter(this, arguments, void 0, function* (requestParameters = {}, initOverrides) {
            const response = yield this.listProfilesApiV1ProfilesGetRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * List Roles
     */
    listRolesApiV1RolesGetRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (requestParameters['authorization'] != null) {
                headerParameters['authorization'] = String(requestParameters['authorization']);
            }
            const response = yield this.request({
                path: `/api/v1/roles/`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RoleFromJSON));
        });
    }
    /**
     * List Roles
     */
    listRolesApiV1RolesGet() {
        return __awaiter(this, arguments, void 0, function* (requestParameters = {}, initOverrides) {
            const response = yield this.listRolesApiV1RolesGetRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Unsubscribe
     */
    unsubscribeApiV1ProfilesProfileIdUnsubscribePostRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['profileId'] == null) {
                throw new runtime.RequiredError('profileId', 'Required parameter "profileId" was null or undefined when calling unsubscribeApiV1ProfilesProfileIdUnsubscribePost().');
            }
            if (requestParameters['token'] == null) {
                throw new runtime.RequiredError('token', 'Required parameter "token" was null or undefined when calling unsubscribeApiV1ProfilesProfileIdUnsubscribePost().');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (requestParameters['token'] != null) {
                headerParameters['token'] = String(requestParameters['token']);
            }
            const response = yield this.request({
                path: `/api/v1/profiles/{profile_id}/unsubscribe`.replace(`{${"profile_id"}}`, encodeURIComponent(String(requestParameters['profileId']))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            if (this.isJsonMime(response.headers.get('content-type'))) {
                return new runtime.JSONApiResponse(response);
            }
            else {
                return new runtime.TextApiResponse(response);
            }
        });
    }
    /**
     * Unsubscribe
     */
    unsubscribeApiV1ProfilesProfileIdUnsubscribePost(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.unsubscribeApiV1ProfilesProfileIdUnsubscribePostRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Update Group
     */
    updateGroupApiV1GroupsGroupIdPutRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['groupId'] == null) {
                throw new runtime.RequiredError('groupId', 'Required parameter "groupId" was null or undefined when calling updateGroupApiV1GroupsGroupIdPut().');
            }
            if (requestParameters['groupUpdate'] == null) {
                throw new runtime.RequiredError('groupUpdate', 'Required parameter "groupUpdate" was null or undefined when calling updateGroupApiV1GroupsGroupIdPut().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (requestParameters['authorization'] != null) {
                headerParameters['authorization'] = String(requestParameters['authorization']);
            }
            const response = yield this.request({
                path: `/api/v1/groups/{group_id}`.replace(`{${"group_id"}}`, encodeURIComponent(String(requestParameters['groupId']))),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: GroupUpdateToJSON(requestParameters['groupUpdate']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => GroupFromJSON(jsonValue));
        });
    }
    /**
     * Update Group
     */
    updateGroupApiV1GroupsGroupIdPut(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.updateGroupApiV1GroupsGroupIdPutRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Update My Profile
     */
    updateMyProfileApiV1ProfilesMePutRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['myProfileUpdate'] == null) {
                throw new runtime.RequiredError('myProfileUpdate', 'Required parameter "myProfileUpdate" was null or undefined when calling updateMyProfileApiV1ProfilesMePut().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (requestParameters['authorization'] != null) {
                headerParameters['authorization'] = String(requestParameters['authorization']);
            }
            const response = yield this.request({
                path: `/api/v1/profiles/me`,
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: MyProfileUpdateToJSON(requestParameters['myProfileUpdate']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ProfileFromJSON(jsonValue));
        });
    }
    /**
     * Update My Profile
     */
    updateMyProfileApiV1ProfilesMePut(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.updateMyProfileApiV1ProfilesMePutRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Update Profile
     */
    updateProfileApiV1ProfilesProfileIdPutRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['profileId'] == null) {
                throw new runtime.RequiredError('profileId', 'Required parameter "profileId" was null or undefined when calling updateProfileApiV1ProfilesProfileIdPut().');
            }
            if (requestParameters['profileUpdate'] == null) {
                throw new runtime.RequiredError('profileUpdate', 'Required parameter "profileUpdate" was null or undefined when calling updateProfileApiV1ProfilesProfileIdPut().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (requestParameters['authorization'] != null) {
                headerParameters['authorization'] = String(requestParameters['authorization']);
            }
            const response = yield this.request({
                path: `/api/v1/profiles/{profile_id}`.replace(`{${"profile_id"}}`, encodeURIComponent(String(requestParameters['profileId']))),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: ProfileUpdateToJSON(requestParameters['profileUpdate']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ProfileFromJSON(jsonValue));
        });
    }
    /**
     * Update Profile
     */
    updateProfileApiV1ProfilesProfileIdPut(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.updateProfileApiV1ProfilesProfileIdPutRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Upload Avatar
     */
    uploadAvatarApiV1ProfilesMeAvatarPostRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (requestParameters['authorization'] != null) {
                headerParameters['authorization'] = String(requestParameters['authorization']);
            }
            const response = yield this.request({
                path: `/api/v1/profiles/me/avatar`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => GetUploadUrlResponseFromJSON(jsonValue));
        });
    }
    /**
     * Upload Avatar
     */
    uploadAvatarApiV1ProfilesMeAvatarPost() {
        return __awaiter(this, arguments, void 0, function* (requestParameters = {}, initOverrides) {
            const response = yield this.uploadAvatarApiV1ProfilesMeAvatarPostRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
