/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const Costume = {
    Polo: 'POLO',
    Costume: 'COSTUME',
    None: 'NONE'
};
export function instanceOfCostume(value) {
    for (const key in Costume) {
        if (Object.prototype.hasOwnProperty.call(Costume, key)) {
            if (Costume[key] === value) {
                return true;
            }
        }
    }
    return false;
}
export function CostumeFromJSON(json) {
    return CostumeFromJSONTyped(json, false);
}
export function CostumeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function CostumeToJSON(value) {
    return value;
}
export function CostumeToJSONTyped(value, ignoreDiscriminator) {
    return value;
}
