/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the GlobalStats interface.
 */
export function instanceOfGlobalStats(value) {
    if (!('nResponses' in value) || value['nResponses'] === undefined)
        return false;
    if (!('nEvents' in value) || value['nEvents'] === undefined)
        return false;
    if (!('avgResponseTime' in value) || value['avgResponseTime'] === undefined)
        return false;
    if (!('nUpcomingEvent' in value) || value['nUpcomingEvent'] === undefined)
        return false;
    return true;
}
export function GlobalStatsFromJSON(json) {
    return GlobalStatsFromJSONTyped(json, false);
}
export function GlobalStatsFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'nResponses': json['n_responses'],
        'nEvents': json['n_events'],
        'avgResponseTime': json['avg_response_time'],
        'nUpcomingEvent': json['n_upcoming_event'],
    };
}
export function GlobalStatsToJSON(json) {
    return GlobalStatsToJSONTyped(json, false);
}
export function GlobalStatsToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'n_responses': value['nResponses'],
        'n_events': value['nEvents'],
        'avg_response_time': value['avgResponseTime'],
        'n_upcoming_event': value['nUpcomingEvent'],
    };
}
